import {
  GET_TIMESHEETS,
  SHOW_TIMESHEETS_LOADING,
  GET_TIMESHEET_DETAILS,
  CREATE_TIMESHEET,
  UPDATE_TIMESHEET,
  DELETE_TIMESHEET,
  ERROR_TIMESHEET,
} from '../actions/types';

const initialState = {
  timesheet: [],
  timesheetDetails: null,
  timesheetLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_TIMESHEETS_LOADING:
      return {
        ...state,
        timesheetLoading: true,
      };
    case GET_TIMESHEETS:
      return {
        ...state,
        timesheet: payload,
        timesheetLoading: false,
      };
    case GET_TIMESHEET_DETAILS:
      return {
        ...state,
        timesheetDetails: payload,
        timesheetLoading: false,
      };
    case CREATE_TIMESHEET:
      return {
        ...state,
        timesheet: [payload, ...state.timesheet],
        timesheetLoading: false,
      };
    case UPDATE_TIMESHEET:
      return {
        ...state,
        timesheet: state.timesheet.map((time) =>
          time._id === payload._id
            ? {
                ...time,
                days: payload.days,
              }
            : time
        ),
      };
    case DELETE_TIMESHEET:
      return {
        ...state,
        timesheet: state.timesheet.filter((time) => time._id !== payload),
        timesheetLoading: false,
      };
    case ERROR_TIMESHEET:
      return {
        ...state,
        error: payload,
        timesheetLoading: false,
      };

    default:
      return state;
  }
}
