import {
  PHOTOS_ERROR,
  SHOW_PHOTOS_LOADING,
  UPLOAD_PHOTO_SUCCESS,
  DELETE_PHOTO,
  GET_PHOTOS,
  GET_INVENTORY_PHOTOS,
  UPLOAD_INVETORY_PHOTO_SUCCESS,
} from '../actions/types';

const initialState = {
  photolist: [],
  photoID: null,
  photoDetails: null,
  photoLoading: false,
  uploading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PHOTOS_LOADING:
      return {
        ...state,
        photoLoading: true,
      };
    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        photolist: [...payload, ...state.photolist],
        photoLoading: false,
      };
    case UPLOAD_INVETORY_PHOTO_SUCCESS:
      return {
        ...state,
        photolist: state.photolist.concat(
          payload.map((photo) => ({
            _id: photo._id,
            url: photo.downloadurl,
            inventoryID: photo.inventoryID,
          }))
        ),
        photoLoading: false,
      };
    case GET_PHOTOS:
      return {
        ...state,
        photolist: payload,
        photoLoading: false,
      };
    case GET_INVENTORY_PHOTOS:
      return {
        ...state,
        photolist: payload.map((photo) => ({
          _id: photo._id,
          url: photo.downloadurl,
          inventoryID: photo.inventoryID,
        })),
        photoLoading: false,
      };
    case PHOTOS_ERROR:
      return {
        ...state,
        error: payload,
        photoLoading: false,
      };
    case DELETE_PHOTO:
      return {
        ...state,
        photolist: state.photolist.filter(
          (imageitem) => imageitem._id !== payload
        ),
        photoLoading: false,
      };
    default:
      return state;
  }
}
