import {
  GET_CHARTER,
  CHARTER_ERROR,
  SHOW_CHARTER_LOADING,
  GET_CHARTER_DETAILS,
  CREATE_CHARTER_SUCCESS,
  SHOW_EDIT_CHARTER_LOADING,
  EDIT_CHARTER_SUCCESS,
  DELETE_CHARTER,
  MIGRATE_CHARTER_SUCCESS,
} from '../actions/types';

/**
 * maco-magsael-sc-689: Created for Charter - create charter interface
 */

const initialState = {
  charterList: [],
  charterID: null,
  charterDetails: null,
  charterLoading: false,
  editCharterLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_CHARTER_LOADING:
      return {
        ...state,
        charterLoading: true,
      };
    case MIGRATE_CHARTER_SUCCESS:
      return {
        ...state,
        charterLoading: false,
      };
    case SHOW_EDIT_CHARTER_LOADING:
      return {
        ...state,
        editCharterLoading: true,
      };
    case GET_CHARTER:
      return {
        ...state,
        charterList: payload,
        charterLoading: false,
      };
    case EDIT_CHARTER_SUCCESS:
      return {
        ...state,
        charterList: state.charterList.map((charter) =>
          charter._id === payload._id
            ? {
                ...charter,
                charterer: payload.charterer,
                status: payload.status,
                embarkmentPort: payload.embarkmentPort,
                disembarkmentPort: payload.disembarkmentPort,
                embarkmentCountry: payload.embarkmentCountry,
                disembarkmentCountry: payload.disembarkmentCountry,
                comments: payload.comments,
                embarkmentDate: payload.embarkmentDate,
                disembarkmentDate: payload.disembarkmentDate,
              }
            : charter
        ),
        charterDetails: {
          ...payload,
          charterer: payload.charterer,
          status: payload.status,
          embarkmentPort: payload.embarkmentPort,
          disembarkmentPort: payload.disembarkmentPort,
          embarkmentCountry: payload.embarkmentCountry,
          disembarkmentCountry: payload.disembarkmentCountry,
          comments: payload.comments,
          embarkmentDate: payload.embarkmentDate,
          disembarkmentDate: payload.disembarkmentDate,
        },
        editCharterLoading: false,
        charterLoading: false,
      };
    case GET_CHARTER_DETAILS:
      return {
        ...state,
        charterDetails: payload,
        charterLoading: false,
      };
    case CREATE_CHARTER_SUCCESS:
      return {
        ...state,
        charterList: [payload, ...state.charterList],
        charterLoading: false,
      };
    case CHARTER_ERROR:
      return {
        ...state,
        error: payload,
        charterLoading: false,
      };
    case DELETE_CHARTER:
      return {
        ...state,
        charterList: state.charterList.filter(
          (charter) => charter._id !== payload
        ),
        charterLoading: false,
      };
    default:
      return state;
  }
}
