import {
  GET_TYSSALES,
  GET_TYSCLIENTS,
  GET_TYSSALES_DETAILS,
  CREATE_TYSSALES,
  EDIT_TYSSALES,
  DELETE_TYSCLIENT,
  DELETE_TYSSALES,
  SHOW_TYSSALES_LOADING,
  TYSSALES_ERROR,
} from '../actions/types';

/**
 * maco-magsael-sc-691: Createrd for Sales - Create TYS Sales module
 *
 */

const initialState = {
  tyssaleslist: [],
  tysclients: [],
  tyssalesDetails: null,
  tyssalesLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_TYSSALES_LOADING:
      return {
        ...state,
        tyssalesLoading: true,
      };
    case GET_TYSSALES:
      return {
        ...state,
        tyssaleslist: payload,
        tyssalesLoading: false,
      };
    case GET_TYSCLIENTS:
      return {
        ...state,
        tysclients: payload,
        tyssalesLoading: false,
      };
    case GET_TYSSALES_DETAILS:
      return {
        ...state,
        tyssalesDetails: payload,
        tyssalesLoading: false,
      };
    case TYSSALES_ERROR:
      return {
        ...state,
        error: payload,
        tyssalesLoading: false,
      };
    case CREATE_TYSSALES:
      return {
        ...state,
        tyssaleslist: [payload, ...state.tyssaleslist],
        tyssalesLoading: false,
      };
    case EDIT_TYSSALES:
      return {
        ...state,
        tyssalesDetails: payload,
        tyssalesLoading: false,
      };
    case DELETE_TYSCLIENT:
      return {
        ...state,
        tyssalesDetails: payload,
        tyssalesLoading: false,
      };
    case DELETE_TYSSALES:
      return {
        ...state,
        tyssaleslist: state.tyssaleslist.filter((sale) => sale._id !== payload),
        tyssalesLoading: false,
      };
    default:
      return state;
  }
}
