import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// modification start - 10.23.2022 - maco-magsael-sc-604
import { Layout, Typography, Menu } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
// modification end - 10.23.2022 - maco-magsael-sc-604
import Logo from './Logo';
import NavProfile from './NavProfile';
import NavSearch from './NavSearch';
import { onMobileNavToggle } from 'redux/actions/Theme';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'constants/ThemeConstant';
import utils from 'utils';
// modification start - 10.12.2022 - loki-ribeiro-sc-238
import NavPanel from './NavPanel';
// modification end - 10.12.2022 - loki-ribeiro-sc-238
// modification start - 10.14.2022 - loki-ribeiro-sc-241
import { updateNavCollapsed } from 'actions/theme';
import { updateEmail } from 'actions/user';
// modification end - 10.14.2022 - loki-ribeiro-sc-241
import Notification from './Notification';
// modification start - 10.23.2022 - maco-magsael-sc-604
import { APP_PREFIX_PATH } from 'configs/AppConfig';
// modification end - 10.23.2022 - maco-magsael-sc-604
// Modification start - 08.29.2024 - maco-magsael-sc-679
import { getManagementDetails } from 'actions/management';
import LogoManagement from './LogoManagement';
// Modification end - 08.29.2024 - maco-magsael-sc-679

/**
 * loki-ribeiro-sc-238: Save themes for individual users
 * loki-ribeiro-sc-241: Issues with side nav
 * olain-tarcina-sc-479: Notifications module
 * olain-tarcina-sc-550: Notification to update email
 * maco-magsael-sc-604: Help - Add help icon on NavPanel
 * maco-magsael-sc-679: Management - add interface to upload logo for company and boats
 */

const { Header } = Layout;
const { Title } = Typography;

export const HeaderNav = ({
  theme: {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    currentTheme,
    // modification start - 10.14.2022 - loki-ribeiro-sc-241
    themeDetails,
    // modification end - 10.14.2022 - loki-ribeiro-sc-241
  },
  auth: { user },
  // Modification start - 08.29.2024 - maco-magsael-sc-679
  management: { managementDetails, editManagementLoading, managementLoading },
  // Modification end - 08.29.2024 - maco-magsael-sc-679
  onMobileNavToggle,
  isMobile,
  // modification start - 10.14.2022 - loki-ribeiro-sc-241
  updateNavCollapsed,
  // modification end - 10.14.2022 - loki-ribeiro-sc-241
  updateEmail,
  // Modification start - 08.29.2024 - maco-magsael-sc-679
  getManagementDetails,
  // Modification end - 08.29.2024 - maco-magsael-sc-679
}) => {
  const [searchActive, setSearchActive] = useState(false);
  const boatName = user && user.boatName;

  // modification start - 10.14.2022 - loki-ribeiro-sc-241
  const themeID = themeDetails && themeDetails._id;
  const userID = user && user._id;
  // modification end - 10.14.2022 - loki-ribeiro-sc-241
  // Modification start - 08.29.2024 - maco-magsael-sc-679
  const managementID = user?.managementID;

  useEffect(() => {
    if (managementID) {
      getManagementDetails(managementID);
    }
  }, [managementID, getManagementDetails]);
  // Modification end - 08.29.2024 - maco-magsael-sc-679

  // Modification start - 30.11.2023 - olain-tarcina-sc-550
  // const [modalVisible, setModalVisible] = useState(false);
  // const [form] = Form.useForm();
  // const [formData, setFormData] = useState({
  //   email: '',
  // });
  // const email = user?.email;

  // useEffect(() => {
  //   message.destroy();
  //   if (!email) {
  //     message.warning({
  //       content: 'Your email is not provided or empty. Click to update.',
  //       duration: 0,
  //       maxCount: 1,
  //       onClick: handleWarningClick,
  //     });
  //   } else {
  //     message.destroy();
  //   }
  //   // eslint-disable-next-line
  // }, [email]);
  // Modification end - 30.11.2023 - olain-tarcina-sc-550

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      // modification start - 10.14.2022 - loki-ribeiro-sc-241
      updateNavCollapsed(!navCollapsed, userID, themeID);
      // modification end - 10.14.2022 - loki-ribeiro-sc-241
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === 'dark' ? '#00000' : '#ffffff'
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  // Modification start - 30.11.2023 - olain-tarcina-sc-550
  // const handleWarningClick = () => {
  //   setModalVisible(true);
  //   setFormData({ ...formData, userID: user?._id });
  // };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async (value) => {
  //   await updateEmail(formData);
  //   setModalVisible(false);
  //   message.destroy();
  // };
  // Modification end - 30.11.2023 - olain-tarcina-sc-550

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        {/* // Modification start - 08.29.2024 - maco-magsael-sc-679 */}
        {managementID && managementDetails?.images.length > 0 ? (
          <LogoManagement
            logoType={navMode}
            logoURL={managementDetails?.images[0]?.url}
          />
        ) : (
          <Logo logoType={navMode} />
        )}
        {/* // Modification end - 08.29.2024 - maco-magsael-sc-679 */}
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            {!isMobile ? (
              <Title level={3} style={{ margin: '24px', color: '#ff5722' }}>
                {boatName}
              </Title>
            ) : null}

            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            {/* // modification start - 10.23.2022 - maco-magsael-sc-604 */}
            <Menu mode="horizontal">
              <Menu.Item key="help">
                <a href={`${APP_PREFIX_PATH}/help`}>
                  <QuestionCircleOutlined className="nav-icon mr-0" />
                </a>
              </Menu.Item>
            </Menu>
            {/* // modification end - 10.23.2022 - maco-magsael-sc-604 */}
            {/* Modification start - 25.09.2023 - olain-tarcina-sc-479 */}
            <Notification />
            {/* Modification start - 25.09.2023 - olain-tarcina-sc-479 */}
            {/* modification start - 10.12.2022 - loki-ribeiro-sc-238 */}
            <NavPanel />
            {/* modification end - 10.12.2022 - loki-ribeiro-sc-238 */}
            <NavProfile isMobile={isMobile} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
      {/* // Modification start - 30.11.2023 - olain-tarcina-sc-550 */}
      {/* <Modal
        title="Update Email"
        centered
        visible={modalVisible}
        closable={false}
        onCancel={() => {}}
        footer={null}
      >
        <Form
          form={form}
          name="class-post"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input your new email!',
              },
            ]}
          >
            <Input name="email" onChange={(e) => handleChange(e)} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal> */}
      {/* // Modification end - 30.11.2023 - olain-tarcina-sc-550 */}
    </Header>
  );
};

HeaderNav.propTypes = {
  updateNavCollapsed: PropTypes.func.isRequired,
  onMobileNavToggle: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateEmail: PropTypes.func.isRequired,
  // Modification start - 08.29.2024 - maco-magsael-sc-679
  management: PropTypes.object.isRequired,
  getManagementDetails: PropTypes.func.isRequired,
  // Modification end - 08.29.2024 - maco-magsael-sc-679
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  auth: state.auth,
  // Modification start - 08.29.2024 - maco-magsael-sc-679
  management: state.management,
  // Modification end - 08.29.2024 - maco-magsael-sc-679
});

export default connect(mapStateToProps, {
  updateNavCollapsed,
  onMobileNavToggle,
  updateEmail,
  // Modification start - 08.29.2024 - maco-magsael-sc-679
  getManagementDetails,
  // Modification end - 08.29.2024 - maco-magsael-sc-679
})(HeaderNav);
