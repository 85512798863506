import axios from 'axios';
import {
  SHOW_PROFILE_LOADING,
  GET_PROFILES,
  GET_PROFILES_CREW,
  PROFILE_ERROR,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DOCS,
  GET_USER_DETAILS,
  SHOW_EDIT_PROFILE_LOADING,
  UPDATE_PROFILE_SIGNATURE,
  EDIT_ONBOARD_SUCCESS,
  SHOW_PROFILE,
} from './types';
import { loadUser } from './auth';
import { setNotification } from 'components/shared-components/notification/Notification';
import { setAlert } from './alert';
import emailjs from '@emailjs/browser';
import { v4 as uuidv4 } from 'uuid';

/** Modifications
 * ymploki-101: Sort onboard first
 * ympolain-177 - Edit profile for shipyard users
 * olain-tarcina-sc-425: Signature - Create interface for uploading signatures
 * management-module: Build Management Interface
 * maco-magsael-sc-531: Profile - issues with population of userID
 * olain-tarcina-sc-522 - change login to email, change/forgot password
 * new-shipyard
 * maco-magsael-sc-692: Payroll - Create payroll module

 */

// Get all profiles for boat
export const getProfiles = (boatID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/boat/${boatID}`);

    dispatch({
      type: GET_PROFILES_CREW,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// * Modification start - 11.08.2023 -  maco-magsael-sc-531
// Get all profiles login for boat
export const getLoginProfiles = (boatID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/boat/login/${boatID}`);

    dispatch({
      type: GET_PROFILES_CREW,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// * Modification end - 11.08.2023 -  maco-magsael-sc-531

// Modification start - 07.22.2023 - management-module
// Get all profiles for boat in management
export const getManagementProfiles = (managementID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/managementboat/${managementID}`);

    dispatch({
      type: GET_PROFILES_CREW,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// modification end - 07.22.2023 - management-module

// Get all profiles for boat for accounting
export const getAccountingProfiles = (boatID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/boat/accounting/${boatID}`);

    dispatch({
      type: GET_PROFILES_CREW,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get profile details by id
export const getProfileDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });
    const res = await axios.get(`/api/profile/profiledetails/${id}`);

    dispatch({
      type: GET_PROFILE_DETAILS,
      payload: res.data,
    });

    const userID = res.data.userID;

    const res2 = await axios.get(`/api/docs/profiledocs/${userID}`);
    dispatch({
      type: GET_PROFILE_DOCS,
      payload: res2.data,
    });

    const res3 = await axios.get(`/api/users/userdetails/${id}`);

    dispatch({
      type: GET_USER_DETAILS,
      payload: res3.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get profile details by userID
export const getProfileUserID = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });
    const res = await axios.get(`/api/profile/profiledetailsbyuserid/${id}`);

    dispatch({
      type: GET_PROFILE_DETAILS,
      payload: res.data,
    });

    const res2 = await axios.get(`/api/docs/profiledocs/${id}`);
    dispatch({
      type: GET_PROFILE_DOCS,
      payload: res2.data,
    });

    const res3 = await axios.get(`/api/users/userdetailsbyid/${id}`);

    dispatch({
      type: GET_USER_DETAILS,
      payload: res3.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getProfileDetailsByUserID = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });
    const res = await axios.get(`/api/profile/profiledetailsbyuserid/${id}`);

    dispatch({
      type: SHOW_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// modification start - 16.09.2022 - ympolain-177
export const getShipyardProfile = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/profiledetailsbyuserid/${id}`);

    dispatch({
      type: GET_PROFILE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// modification end - 16.09.2022 - ympolain-177

// Modification start 08.09.2022 - ymploki-101
// Get all profiles for boat
export const getOnboard = (boatID) => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/onboard/${boatID}`);

    dispatch({
      type: GET_PROFILES_CREW,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Modification end - 08.09.2022 - ymploki-101

// Get all profiles for boat
export const getYmpProfiles = () => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/ympprofiles`);

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Modification start - 04.24.2024 - new-shipyard
// Get all profiles with email
export const getEmailProfiles = () => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const res = await axios.get(`/api/profile/all_profiles`);

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    console.info('error', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Modification end - 04.24.2024 - new-shipyard

// Modification start - 10.11.2023 - olain-tarcina-sc-522
// Forgot password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    const SERVICE_ID = 'service_toqfoo9';
    const TEMPLATE_ID = 'template_y9tanf9';
    const USER_ID = 'user_WtCjGRDorUo3I1OmblHuB';
    emailjs.init(USER_ID);

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const generateToken = () => {
      const token = uuidv4();
      return token;
    };

    const resetToken = generateToken();
    const resetLink = `${window.location.origin}/auth/reset-password/${resetToken}`;

    const body = JSON.stringify({
      resetToken,
      email,
    });

    try {
      const res = await axios.post('/api/profile/store-token', body, config);

      if (res.data) {
        const params = {
          resetLink,
          email,
        };

        dispatch(setAlert('Password reset link sent', 'success'));
        await emailjs.send(SERVICE_ID, TEMPLATE_ID, params);
      }
    } catch (err) {
      console.info('error', err);
    }
  } catch (err) {
    const errors = err.response?.data?.errors;
    console.info('error', errors);
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      dispatch({
        type: PROFILE_ERROR,
        // payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};

export const changePassword = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const newPassword = formData.newPassword;
  const token = formData.token;

  const body = JSON.stringify({
    newPassword,
  });
  try {
    const res = await axios.post(
      `/api/profile/reset-password/${token}`,
      body,
      config
    );
    if (res.data) {
      dispatch(setAlert('Successfully changed password', 'success'));
    }
  } catch (err) {
    const errors = err.response?.data?.errors;
    console.info('error', errors);
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      dispatch({
        type: PROFILE_ERROR,
        // payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};

// Modification end - 10.11.2023 - olain-tarcina-sc-522

// Edit profile details
export const editProfile =
  (
    formData,
    userID,
    profilePhoto,
    // Modification start - 05.23.2023 - olain-tarcina-sc-425
    signature
    // Modification end - 05.23.2023 - olain-tarcina-sc-425
  ) =>
  async (dispatch) => {
    dispatch({
      type: SHOW_EDIT_PROFILE_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const firstName = formData.firstName;
    const lastName = formData.lastName;
    const department = formData.department;
    const jobtitle = formData.jobtitle;
    const mailing = formData.mailing;
    const role = formData.role;
    const email = formData.email;
    const birthDate = new Date(formData.birthDate);
    const birthPlace = formData.birthPlace;
    const gender = formData.gender;
    const address = formData.address;
    const city = formData.city;
    const postal = formData.postal;
    const phone = formData.phone;
    const skype = formData.skype;
    const phone2 = formData.phone2;
    const ecFullName = formData.ecFullName;
    const ecContactNum = formData.ecContactNum;
    const ecRelation = formData.ecRelation;
    const ecEmail = formData.ecEmail;
    const ecAddress = formData.ecAddress;
    const ecSkype = formData.ecSkype;
    const newEdit = false;
    // modification start - 10.07.2024 - maco-magsael-sc-692
    const salaryRate = formData.salaryRate;
    const contractStart = formData.contractStart;
    // modification end - 10.07.2024 - maco-magsael-sc-692

    const body = JSON.stringify({
      firstName,
      lastName,
      department,
      jobtitle,
      mailing,
      role,
      profilePhoto,
      userID,
      email,
      birthDate,
      birthPlace,
      gender,
      address,
      city,
      postal,
      phone,
      skype,
      phone2,
      ecFullName,
      ecContactNum,
      ecRelation,
      ecEmail,
      ecAddress,
      ecSkype,
      newEdit,
      // Modification start - 05.23.2023 - olain-tarcina-sc-425
      signature,
      // Modification end - 05.23.2023 - olain-tarcina-sc-425
      // modification start - 10.07.2024 - maco-magsael-sc-692
      salaryRate,
      contractStart,
      // modification end - 10.07.2024 - maco-magsael-sc-692
    });

    try {
      const res = await axios.post('/api/profile', body, config);

      dispatch({
        type: GET_PROFILE_DETAILS,
        payload: res.data,
      });

      if (profilePhoto) {
        const body2 = JSON.stringify({
          profilePhoto,
          userID,
        });

        await axios.put('/api/users/recentphoto', body2, config);
      }
      dispatch(loadUser());
      setNotification('success', 'Profile changes have been saved');
    } catch (err) {
      console.info('err', err);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          setNotification('error', error.msg);
        });
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Updating profile failed');
    }
  };

// Modification start - 17.03.2023 - olain-tarcina-sc-379
// Update Petty Cash
export const updatePettyCash =
  (boatID, userID, formData) => async (dispatch) => {
    dispatch({
      type: SHOW_PROFILE_LOADING,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const amount = formData.amount;
    const currency = formData.currency;

    const body = JSON.stringify({
      boatID,
      userID,
      amount,
      currency,
    });

    try {
      const res = await axios.put('/api/profile/pettycash', body, config);
      dispatch({
        type: GET_PROFILES_CREW,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      console.info('err', err);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => {
          setNotification('error', error.msg);
        });
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
      setNotification('error', 'Updating petty cash failed');
    }
  };
// Modification end - 23.03.2023 - olain-tarcina-sc-379

// Modification start - 05.22.2023 - olain-tarcina-sc-425
export const updateSignature = (formData) => async (dispatch) => {
  dispatch({
    type: SHOW_EDIT_PROFILE_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const status = formData.status;
  const downloadUrl = formData.downloadUrl;
  const profileID = formData.profileID;

  const body = JSON.stringify({
    status,
    profileID,
    downloadUrl,
  });

  try {
    const res = await axios.put('/api/profile/signature', body, config);

    dispatch({
      type: UPDATE_PROFILE_SIGNATURE,
      payload: res.data,
    });

    setNotification('success', 'Signature status has been updated');
  } catch (err) {
    console.info('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        setNotification('error', error.msg);
      });
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
// Modification end - 05.22.2023 - olain-tarcina-sc-425

// Edit onboard status
export const changeOnboard = (onboard, userID) => async (dispatch) => {
  dispatch({
    type: SHOW_EDIT_PROFILE_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    onboard,
  });

  try {
    const res = await axios.post(
      `/api/profile/onboard_status/${userID}`,
      body,
      config
    );

    dispatch({
      type: EDIT_ONBOARD_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: GET_PROFILE_DETAILS,
      payload: res.data,
    });

    dispatch(loadUser());
    setNotification('success', 'Onboard status have been saved');
  } catch (err) {
    console.info('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        setNotification('error', error.msg);
      });
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Updating status failed');
  }
};

// Edit Notifications
export const changeNotifications = (status, field, id) => async (dispatch) => {
  dispatch({
    type: SHOW_EDIT_PROFILE_LOADING,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    status,
    field,
    id,
  });

  try {
    const res = await axios.put('/api/profile/notifications', body, config);

    dispatch({
      type: GET_PROFILE_DETAILS,
      payload: res.data,
    });

    setNotification('success', 'Notifications have changed');
  } catch (err) {
    console.info('err', err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => {
        setNotification('error', error.msg);
      });
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    setNotification('error', 'Updating notifications failed');
  }
};
