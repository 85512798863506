import {
  GET_CONTRACT,
  CONTRACT_ERROR,
  SHOW_CONTRACT_LOADING,
  GET_CONTRACT_DETAILS,
  CREATE_CONTRACT_SUCCESS,
  SHOW_EDIT_CONTRACT_LOADING,
  EDIT_CONTRACT_SUCCESS,
  DELETE_CONTRACT,
  MIGRATE_CONTRACT_SUCCESS,
} from '../actions/types';

/**
 * maco-magsael-sc-689: Created for Charter - create charter interface
 */

const initialState = {
  contractList: [],
  contractID: null,
  contractDetails: null,
  contractLoading: false,
  editContractLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_CONTRACT_LOADING:
      return {
        ...state,
        contractLoading: true,
      };
    case MIGRATE_CONTRACT_SUCCESS:
      return {
        ...state,
        contractLoading: false,
      };
    case SHOW_EDIT_CONTRACT_LOADING:
      return {
        ...state,
        editContractLoading: true,
      };
    case GET_CONTRACT:
      return {
        ...state,
        contractList: payload,
        contractLoading: false,
      };
    case EDIT_CONTRACT_SUCCESS:
      return {
        ...state,
        contractList: state.contractList.map((contract) =>
          contract._id === payload._id
            ? {
                ...contract,
                date: payload.date,
                amount: payload.amount,
                description: payload.description,
                attachments: payload.attachments,
                currency: payload.currency,
              }
            : contract
        ),
        contractDetails: {
          ...payload,
          date: payload.date,
          amount: payload.amount,
          description: payload.description,
          attachments: payload.attachments,
          funds: payload.funds,
        },
        editContractLoading: false,
        contractLoading: false,
      };
    case GET_CONTRACT_DETAILS:
      return {
        ...state,
        contractDetails: payload,
        contractLoading: false,
      };
    case CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        contractList: [payload, ...state.contractList],
        contractLoading: false,
      };
    case CONTRACT_ERROR:
      return {
        ...state,
        error: payload,
        contractLoading: false,
      };
    case DELETE_CONTRACT:
      return {
        ...state,
        contractList: state.contractList.filter(
          (contract) => contract._id !== payload
        ),
        contractLoading: false,
      };
    default:
      return state;
  }
}
