// Modification start - 08.29.2024 - maco-magsael-sc-679
import React, { useEffect } from "react";
// Modification end - 08.29.2024 - maco-magsael-sc-679
import { Drawer } from "antd";
import { connect } from "react-redux";
import { NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";
import { onMobileNavToggle } from "redux/actions/Theme";
import Logo from "./Logo";
import Flex from "components/shared-components/Flex";
import { ArrowLeftOutlined } from "@ant-design/icons";
// Modification start - 08.29.2024 - maco-magsael-sc-679
import PropTypes from "prop-types";
import { getManagementDetails } from "actions/management";
import LogoManagement from "./LogoManagement";
// Modification end - 08.29.2024 - maco-magsael-sc-679

/**
 * maco-magsael-sc-679: Management - add interface to upload logo for company and boats
 *
 */

export const MobileNav = ({
  sideNavTheme,
  mobileNav,
  onMobileNavToggle,
  routeInfo,
  hideGroupTitle,
  localization = true,
  // Modification start - 08.29.2024 - maco-magsael-sc-679
  auth: { user },
  management: { managementDetails, editManagementLoading, managementLoading },
  getManagementDetails,
  // Modification end - 08.29.2024 - maco-magsael-sc-679
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };

  // Modification start - 08.29.2024 - maco-magsael-sc-679
  const managementID = user?.managementID;

  useEffect(() => {
    if (managementID) {
      getManagementDetails(managementID);
    }
  }, [managementID, getManagementDetails]);
  // Modification end - 08.29.2024 - maco-magsael-sc-679

  const onClose = () => {
    onMobileNavToggle(false);
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          {/* // Modification start - 08.29.2024 - maco-magsael-sc-679 */}
          {managementID && managementDetails?.images.length > 0 ? (
            <LogoManagement
              mobileLogo={true}
              logoURL={managementDetails?.images[0]?.url}
            />
          ) : (
            <Logo mobileLogo={true} />
          )}
          {/* // Modification end - 08.29.2024 - maco-magsael-sc-679 */}
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

// Modification start - 08.29.2024 - maco-magsael-sc-679
MobileNav.propTypes = {
  auth: PropTypes.object.isRequired,
  management: PropTypes.object.isRequired,
  getManagementDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { auth, management, theme } = state;
  // Modification end - 08.29.2024 - maco-magsael-sc-679
  const { navCollapsed, sideNavTheme, mobileNav } = theme;
  // Modification start - 08.29.2024 - maco-magsael-sc-679

  return {
    auth,
    management,
    navCollapsed,
    sideNavTheme,
    mobileNav,
  };
  // Modification emd - 08.29.2024 - maco-magsael-sc-679
};

// Modification start - 08.29.2024 - maco-magsael-sc-679
export default connect(mapStateToProps, {
  onMobileNavToggle,
  getManagementDetails,
})(MobileNav);
// Modification end - 08.29.2024 - maco-magsael-sc-679
