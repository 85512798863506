import {
  GET_ALLOWANCE,
  ALLOWANCE_ERROR,
  SHOW_ALLOWANCE_LOADING,
  GET_ALLOWANCE_DETAILS,
  CREATE_ALLOWANCE_SUCCESS,
  SHOW_EDIT_ALLOWANCE_LOADING,
  EDIT_ALLOWANCE_SUCCESS,
  DELETE_ALLOWANCE,
  MIGRATE_ALLOWANCE_SUCCESS,
} from '../actions/types';

/**
 * maco-magsael-sc-689: Created for Charter - create charter interface
 */

const initialState = {
  allowanceList: [],
  allowanceID: null,
  allowanceDetails: null,
  allowanceLoading: false,
  editAllowanceLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_ALLOWANCE_LOADING:
      return {
        ...state,
        allowanceLoading: true,
      };
    case MIGRATE_ALLOWANCE_SUCCESS:
      return {
        ...state,
        allowanceLoading: false,
      };
    case SHOW_EDIT_ALLOWANCE_LOADING:
      return {
        ...state,
        editAllowanceLoading: true,
      };
    case GET_ALLOWANCE:
      return {
        ...state,
        allowanceList: payload,
        allowanceLoading: false,
      };
    case EDIT_ALLOWANCE_SUCCESS:
      return {
        ...state,
        allowanceList: state.allowanceList.map((allowance) =>
          allowance._id === payload._id
            ? {
                ...allowance,
                date: payload.date,
                amount: payload.amount,
                description: payload.description,
                attachments: payload.attachments,
                funds: payload.funds,
              }
            : allowance
        ),
        allowanceDetails: {
          ...payload,
          date: payload.date,
          amount: payload.amount,
          description: payload.description,
          attachments: payload.attachments,
          funds: payload.funds,
        },
        editAllowanceLoading: false,
        allowanceLoading: false,
      };
    case GET_ALLOWANCE_DETAILS:
      return {
        ...state,
        allowanceDetails: payload,
        allowanceLoading: false,
      };
    case CREATE_ALLOWANCE_SUCCESS:
      return {
        ...state,
        allowanceList: [payload, ...state.allowanceList],
        allowanceLoading: false,
      };
    case ALLOWANCE_ERROR:
      return {
        ...state,
        error: payload,
        allowanceLoading: false,
      };
    case DELETE_ALLOWANCE:
      return {
        ...state,
        allowanceList: state.allowanceList.filter(
          (allowance) => allowance._id !== payload
        ),
        allowanceLoading: false,
      };
    default:
      return state;
  }
}
