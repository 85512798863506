import {
  SHOW_YMPLITEBOAT_LOADING,
  GET_YMPLITEBOAT_DETAILS,
  YMPLITEBOAT_ERROR,
  GET_YMPLITEBOATS,
  SHOW_EDIT_YMPLITEBOAT_LOADING,
  DELETE_YMPLITEBOATS,
} from '../actions/types';

const initialState = {
  liteympboatlist: [],
  liteympboatID: null,
  liteympboatdetails: null,
  liteympboatLoading: false,
  liteympeditBoatLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_YMPLITEBOAT_LOADING:
      return {
        ...state,
        liteympboatLoading: true,
      };
    case SHOW_EDIT_YMPLITEBOAT_LOADING:
      return {
        ...state,
        editBoatLoading: true,
      };
    case GET_YMPLITEBOATS:
      return {
        ...state,
        liteympboatlist: payload,
        liteympboatLoading: false,
      };
    case GET_YMPLITEBOAT_DETAILS:
      return {
        ...state,
        liteympboatdetails: payload,
        liteympboatLoading: false,
        liteympeditBoatLoading: false,
      };
    case YMPLITEBOAT_ERROR:
      return {
        ...state,
        error: payload,
        liteympboatLoading: false,
        editBoatLoading: false,
      };
    case DELETE_YMPLITEBOATS:
      return {
        ...state,
        liteympboatlist: state.liteympboatlist.filter(
          (boat) => boat._id !== payload
        ),
        liteympboatLoading: false,
        liteympeditBoatLoading: false,
      };
    default:
      return state;
  }
}
