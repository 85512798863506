import {
  GET_JOBDETAILS_DOCS,
  GET_FOLDERDETAILS,
  DOCS_ERROR,
  SHOW_DOCS_LOADING,
  GET_PROFILE_DOCS,
  SHOW_FOLDER_LOADING,
  SHOW_FILE_LOADING,
  GET_FOLDERS,
  GET_PAGES,
  GET_FILES,
  SHOW_BOTH_LOADING,
  UPLOAD_MANUAL_SUCCESS,
  UPLOAD_SUPPORT_SUCCESS,
  DELETE_DOCS,
  GET_ALL_FILES,
  CREATE_FOLDER_SUCCESS,
  CREATE_FILE_SUCCESS,
  DELETE_FILES,
  SHOW_DONE_LOADING,
  EDIT_FOLDER_SUCCESS,
  SHOW_EDITFOLDER_LOADING,
  DELETE_FOLDER,
  EDIT_FOLDER_PERMISSIONS,
  EDIT_PREVIOUS_SUCCESS,
  EDIT_CURRENT_SUCCESS,
  EDIT_CERTIFICATE,
  // Modification start - 10.16.2024 - maco-magsael-sc-695
  EDIT_CERTIFICATE_LOADING,
  // Modification end - 10.16.2024 - maco-magsael-sc-695
} from '../actions/types';

/** Modifications
 * ymponline-102 - vessel docs folder bug
 * olain-tarcina-sc-293: show pages in equipment details
 * maco-magsael-sc-320: Vessel Docs Sub-folder and files inside the deleted folder shows when clicking Search All
 * olain-tarcina-sc-412: Vessel Docs - make expiry dates editable
 * maco-magsael-sc-695: Vessel Docs - changes to certificate
 */

const initialState = {
  doclist: [],
  docID: null,
  docDetails: null,
  folderDetails: null,
  folderlist: [],
  filelist: [],
  allFiles: [],
  // Modification start - 07.11.2022 - olain-tarcina-sc-293
  pagelist: [],
  // Modification end - 07.11.2022 - olain-tarcina-sc-293
  docLoading: false,
  folderLoading: false,
  editfolderLoading: false,
  fileLoading: false,
  // Modification start - 10.16.2024 - maco-magsael-sc-695
  certLoading: false,
  // Modification end - 10.16.2024 - maco-magsael-sc-695
  uploading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_DOCS_LOADING:
      return {
        ...state,
        docLoading: true,
      };
    case SHOW_FOLDER_LOADING:
      return {
        ...state,
        folderLoading: true,
      };
    case SHOW_EDITFOLDER_LOADING:
      return {
        ...state,
        editfolderLoading: true,
      };
    case SHOW_FILE_LOADING:
      return {
        ...state,
        fileLoading: true,
      };
    case SHOW_BOTH_LOADING:
      return {
        ...state,
        folderLoading: true,
        fileLoading: true,
      };
    // Modification start - 10.16.2024 - maco-magsael-sc-695
    case EDIT_CERTIFICATE_LOADING:
      return {
        ...state,
        certLoading: true,
      };
    // Modification end - 10.16.2024 - maco-magsael-sc-695
    case GET_JOBDETAILS_DOCS:
      return {
        ...state,
        doclist: payload,
        docLoading: false,
      };
    // Modification start - 07.11.2022 - olain-tarcina-sc-293
    case GET_PAGES:
      return {
        ...state,
        pagelist: payload,
        docLoading: false,
      };
    // Modification end - 07.11.2022 - olain-tarcina-sc-293
    case GET_PROFILE_DOCS:
      return {
        ...state,
        doclist: payload,
        docLoading: false,
      };
    case GET_FOLDERS:
      return {
        ...state,
        folderlist: payload,
        folderDetails: null,
        folderLoading: false,
      };
    case GET_FOLDERDETAILS:
      return {
        ...state,
        folderDetails: payload,
        folderLoading: false,
      };
    case GET_ALL_FILES:
      return {
        ...state,
        allFiles: payload,
        filelist: payload.filter(
          (file) => file.mainFile === true || file.mainFolder === true
        ),
        folderDetails: null,
        fileLoading: false,
      };
    case GET_FILES:
      return {
        ...state,
        filelist: state.allFiles.filter(
          (file) => file.mainfolderID === payload
        ),
        fileLoading: false,
      };
    case CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        folderlist: [payload, ...state.folderlist],
        filelist: [payload, ...state.filelist],
        allFiles: [payload, ...state.allFiles],
        folderLoading: false,
        editfolderLoading: false,
      };
    case CREATE_FILE_SUCCESS:
      return {
        ...state,
        allFiles: state.allFiles.concat(payload),
        filelist: state.filelist.concat(payload),
        fileLoading: false,
      };
    case UPLOAD_MANUAL_SUCCESS:
      return {
        ...state,
        doclist: payload,
        docLoading: false,
      };
    case UPLOAD_SUPPORT_SUCCESS:
      return {
        ...state,
        doclist: state.doclist.concat(payload),
        docLoading: false,
      };
    case EDIT_FOLDER_SUCCESS:
      return {
        ...state,
        filelist: state.filelist.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                name: payload.name,
              }
            : folder
        ),
        folderlist: state.folderlist.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                name: payload.name,
              }
            : folder
        ),
        allFiles: state.allFiles.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                name: payload.name,
              }
            : folder
        ),
        folderDetails: payload,
        folderLoading: false,
        editfolderLoading: false,
      };
    case EDIT_PREVIOUS_SUCCESS:
      return {
        ...state,
        doclist: state.doclist.map((doc) =>
          doc._id === payload._id
            ? {
                ...doc,
                primary: payload.primary,
              }
            : doc
        ),
      };
    case EDIT_CURRENT_SUCCESS:
      return {
        ...state,
        doclist: state.doclist.map((doc) =>
          doc._id === payload._id
            ? {
                ...doc,
                primary: payload.primary,
              }
            : doc
        ),
        docLoading: false,
      };
    case EDIT_FOLDER_PERMISSIONS:
      return {
        ...state,
        folderDetails: payload,
      };
    // // Modification start - 05.08.2023 - olain-tarcina-sc-412
    case EDIT_CERTIFICATE:
      return {
        ...state,
        filelist: state.filelist.map((folder) =>
          folder._id === payload._id
            ? {
                ...folder,
                expDate: payload.expDate,
                // Modification start - 10.16.2024 - maco-magsael-sc-695
                name: payload.name,
                // Modification end - 10.16.2024 - maco-magsael-sc-695
              }
            : folder
        ),
        // Modification start - 10.16.2024 - maco-magsael-sc-695
        certLoading: false,
        // Modification end - 10.16.2024 - maco-magsael-sc-695
      };
    // // Modification end - 05.08.2023 - olain-tarcina-sc-412
    case DELETE_DOCS:
      return {
        ...state,
        doclist: state.doclist.filter((file) => file._id !== payload),
        docLoading: false,
      };
    case DELETE_FILES:
      return {
        ...state,
        // Modification start - 1.31.2023 - maco-magsael-sc-320
        allFiles: state.allFiles.filter(
          (file) => file._id !== payload && file.mainfolderID !== payload
        ),
        filelist: state.filelist.filter(
          (file) => file._id !== payload && file.mainfolderID !== payload
        ),
        folderlist: state.folderlist.filter(
          (file) => file._id !== payload && file.mainfolderID !== payload
        ),
        // Modification end - 1.31.2023 - maco-magsael-sc-320

        // Modification - start - 04.07.2022 - ymponline-102
        folderLoading: false,
        fileLoading: false,
        folderDetails: null,
        // Modification - end - 04.07.2022 - ymponline-102
      };
    case DELETE_FOLDER:
      return {
        ...state,
        allFiles: state.allFiles.filter(
          (file) => file.mainfolderID !== payload
        ),
        filelist: state.filelist.filter(
          (file) => file.mainfolderID !== payload
        ),
        folderlist: state.folderlist.filter((folder) => folder._id !== payload),
        folderLoading: false,
        fileLoading: false,
        folderDetails: null,
      };
    case SHOW_DONE_LOADING:
      return {
        ...state,
        fileLoading: false,
      };
    case DOCS_ERROR:
      return {
        ...state,
        error: payload,
        docLoading: false,
        folderLoading: false,
        fileLoading: false,
      };
    default:
      return state;
  }
}
