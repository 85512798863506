import {
  GET_PAYROLL,
  PAYROLL_ERROR,
  SHOW_PAYROLL_LOADING,
  GET_PAYROLL_DETAILS,
  CREATE_PAYROLL_SUCCESS,
  SHOW_EDIT_PAYROLL_LOADING,
  EDIT_PAYROLL_SUCCESS,
  DELETE_PAYROLL,
  MIGRATE_PAYROLL_SUCCESS,
} from '../actions/types';

/**
 * maco-magsael-sc-692: Created for Payroll - Create payroll module
 *
 */

const initialState = {
  payrollList: [],
  payrollID: null,
  payrollDetails: null,
  payrollLoading: false,
  editPayrollLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_PAYROLL_LOADING:
      return {
        ...state,
        payrollLoading: true,
      };
    case MIGRATE_PAYROLL_SUCCESS:
      return {
        ...state,
        payrollLoading: false,
      };
    case SHOW_EDIT_PAYROLL_LOADING:
      return {
        ...state,
        editPayrollLoading: true,
      };
    case GET_PAYROLL:
      return {
        ...state,
        payrollList: payload,
        payrollLoading: false,
      };
    case EDIT_PAYROLL_SUCCESS:
      return {
        ...state,
        payrollList: state.payrollList.map((payroll) =>
          payroll._id === payload._id
            ? {
                ...payroll,
                boatID: payload.boatID,
                startDate: payload.startDate,
                endDate: payload.endDate,
                contractStart: payload.contractStart,
                salaryRate: payload.salaryRate,
                bonus: payload.bonus,
                cashAdvance: payload.cashAdvance,
                withoutPay: payload.withoutPay,
                notes: payload.notes,
                totalSalary: payload.totalSalary,
              }
            : payroll
        ),
        payrollDetails: {
          ...payload,
          boatID: payload.boatID,
          startDate: payload.startDate,
          endDate: payload.endDate,
          contractStart: payload.contractStart,
          salaryRate: payload.salaryRate,
          bonus: payload.bonus,
          cashAdvance: payload.cashAdvance,
          withoutPay: payload.withoutPay,
          notes: payload.notes,
          totalSalary: payload.totalSalary,
        },
        editPayrollLoading: false,
        payrollLoading: false,
      };
    case GET_PAYROLL_DETAILS:
      return {
        ...state,
        payrollDetails: payload,
        payrollLoading: false,
      };
    case CREATE_PAYROLL_SUCCESS:
      return {
        ...state,
        payrollList: [payload, ...state.payrollList],
        payrollLoading: false,
      };
    case PAYROLL_ERROR:
      return {
        ...state,
        error: payload,
        payrollLoading: false,
      };
    case DELETE_PAYROLL:
      return {
        ...state,
        payrollList: state.payrollList.filter(
          (payroll) => payroll._id !== payload
        ),
        payrollLoading: false,
      };
    default:
      return state;
  }
}
