import {
  HomeOutlined,
  FormOutlined,
  TableOutlined,
  ShoppingCartOutlined,
  SnippetsOutlined,
  // BarsOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  FolderOpenOutlined,
  FileOutlined,
  TagOutlined,
  ToolOutlined,
  UserSwitchOutlined,
  QuestionCircleOutlined,
  // modification start - 08.29.2022 - ympshipyard
  AppstoreAddOutlined,
  // ContainerOutlined,
  // DatabaseOutlined,
  //modification end - 08.29.2022 - ympshipyard
  // modification start - 12.16.2022 - loki-ribeiro-sc-277
  CreditCardOutlined,
  // modification end - 12.16.2022 - loki-ribeiro-sc-277
  // Modification start - 03.07.2023 - olain-tarcina-sc-451
  LineChartOutlined,
  // Modification end - 12.07.2023 - olain-tarcina-sc-451
  // modification start - 08.04.2023 - maco-magsael-sc-458
  CalendarOutlined,
  // modification end - 08.04.2023 - maco-magsael-sc-458
  // Modification start - 07.22.2023 - management-module
  ApartmentOutlined,
  // Modification end - 07.22.2023 - management-module
  // Modification start - 08.22.2024 - nmaco-magsael-sc-677
  ThunderboltOutlined,
  // Modification end - 08.22.2024 - nmaco-magsael-sc-677
  // Modification start - 09.22.2024 - maco-magsael-sc-689
  FileDoneOutlined,
  // Modification end - 09.22.2024 - maco-magsael-sc-689
  // Modification start - 09.26.2024 - maco-magsael-sc-691
  AreaChartOutlined,
  // Modification end - 09.26.2024 - maco-magsael-sc-691
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

/**
 * ympshipyard: shipyard interface
 * loki-ribero-sc-277: new logsbook setup
 * olain-tarcina-sc-442: remove watchkeeper and vacation leave links
 * olain-tarcina-sc-451: sales module
 * maco-magsael-sc-458: Calendars - develop calendar module
 * management-module: build management interface
 * new-shipyard: redo shipyard interface
 * maco-magsael-sc-677: YMP Lite - Interface for lite equipment and jobs
 * maco-magsael-sc-689: Charter - create charter interface
 * maco-magsael-sc-691: Sales - Create TYS Sales module
 */

const jobsNavTree = [
  {
    key: 'jobs',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'jobs',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

// Modification start - 07.22.2023 - management-module
const managementNavTree = [
  {
    key: 'management',
    path: `${APP_PREFIX_PATH}/managementhome`,
    title: 'management',
    icon: ApartmentOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
// Modification end - 07.22.2023 - management-module

const logbookNavTree = [
  {
    key: 'logbook',
    // modification start- 10.31.2022 - loki-ribeiro-sc-277
    path: `${APP_PREFIX_PATH}/log`,
    // modification end- 10.31.2022 - loki-ribeiro-sc-277
    title: 'logbook',
    icon: FormOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const equipmentNavTree = [
  {
    key: 'equipmentspage',
    path: `${APP_PREFIX_PATH}/equipment`,
    title: 'equipment',
    icon: TableOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const inventoryNavTree = [
  {
    key: 'inventory',
    path: `${APP_PREFIX_PATH}/inventory`,
    title: 'inventory',
    icon: ShoppingCartOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const reportsNavTree = [
  {
    key: 'reports',
    path: `${APP_PREFIX_PATH}/reports`,
    title: 'reports',
    icon: SnippetsOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

// Modification start - 29.06.2023 - olain-tarcina-sc-442
// const watchkeepingNavTree = [
//   {
//     key: 'watchkeeping',
//     path: `${APP_PREFIX_PATH}/watchkeeping`,
//     title: 'watchkeeping',
//     icon: BarsOutlined,
//     breadcrumb: false,
//     submenu: [],
//   },
// ];
// Modification end - 29.06.2023 - olain-tarcina-sc-442

const employeesNavTree = [
  {
    key: 'employees',
    path: `${APP_PREFIX_PATH}/employees`,
    title: 'crew',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const guestsNavTree = [
  {
    key: 'guests',
    path: `${APP_PREFIX_PATH}/guests`,
    title: 'guests',
    icon: UsergroupAddOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const docsNavTree = [
  {
    key: 'documents',
    path: `${APP_PREFIX_PATH}/vesseldocs`,
    title: 'vesseldocs',
    icon: FolderOpenOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const ismNavTree = [
  {
    key: 'ism',
    path: `${APP_PREFIX_PATH}/ism`,
    title: 'ism',
    icon: FileOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const supplierNavTree = [
  {
    key: 'supplier',
    path: `${APP_PREFIX_PATH}/supplier`,
    title: 'supplier',
    icon: TagOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const sparepartsNavTree = [
  {
    key: 'spareparts',
    path: `${APP_PREFIX_PATH}/spareparts`,
    title: 'spareparts',
    icon: ToolOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

// modification start - 12.16.2022 - loki-ribeiro-sc-277
const accountingNavTree = [
  {
    key: 'accounting',
    path: `${APP_PREFIX_PATH}/accounting`,
    title: 'accounting',
    icon: CreditCardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
// modification end - 12.16.2022 - loki-ribeiro-sc-277

// Modification start - 03.07.2023 - olain-tarcina-sc-451
const salesNavTree = [
  {
    key: 'sales',
    path: `${APP_PREFIX_PATH}/sales`,
    title: 'sales',
    icon: LineChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
// Modification end - 03.07.2023 - olain-tarcina-sc-451

const superadminNavTree = [
  {
    key: 'superadmin',
    path: `${APP_PREFIX_PATH}/superadmin`,
    title: 'superadmin',
    icon: UserSwitchOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const helpNavTree = [
  {
    key: 'help',
    path: `${APP_PREFIX_PATH}/help`,
    title: 'help',
    icon: QuestionCircleOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

//modification start - 08.29.2022 - ympshipyard
//modification start - 03.21.2024 - new-shipyard
const shipyardNavTree = [
  {
    key: 'shipyard',
    path: `${APP_PREFIX_PATH}/shipyard`,
    title: 'shipyard',
    icon: AppstoreAddOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
//modification end - 03.21.2024 - new-shipyard
// modificatio end - 08.29.2022 - ympshipyard

// modification start - 08.04.2023 - maco-magsael-sc-458
const calendarNavTree = [
  {
    key: 'calendar',
    path: `${APP_PREFIX_PATH}/calendar`,
    title: 'calendar',
    icon: CalendarOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
// modification end - 08.04.2023 - maco-magsael-sc-458

// Modification start - 08.22.2024 - nmaco-magsael-sc-677
const liteYmpNavTree = [
  {
    key: 'ymplite',
    path: `${APP_PREFIX_PATH}/liteymp`,
    title: 'ymplite',
    icon: ThunderboltOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
// Modification end - 08.22.2024 - nmaco-magsael-sc-677

// Modification start - 09.22.2024 - maco-magsael-sc-689
const charterNavTree = [
  {
    key: 'charter',
    path: `${APP_PREFIX_PATH}/charter`,
    title: 'charter',
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
// Modification end - 09.22.2024 - maco-magsael-sc-689

// Modification start - 09.26.2024 - maco-magsael-sc-691
const tyssalesNavTree = [
  {
    key: 'tyssales',
    path: `${APP_PREFIX_PATH}/tyssales`,
    title: 'tyssales',
    icon: AreaChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
// Modification end - 09.26.2024 - maco-magsael-sc-691

const navigationConfig = [
  ...jobsNavTree,
  // modification start - 07.22.2023 - management-module
  ...managementNavTree,
  // modification end - 07.22.2023 - management-module
  ...logbookNavTree,
  ...equipmentNavTree,
  ...inventoryNavTree,
  ...reportsNavTree,
  // modification start - 08.04.2023 - maco-magsael-sc-458
  ...calendarNavTree,
  // modification end - 08.04.2023 - maco-magsael-sc-458
  // ...watchkeepingNavTree,
  ...employeesNavTree,
  ...guestsNavTree,
  ...docsNavTree,
  ...ismNavTree,
  ...supplierNavTree,
  ...sparepartsNavTree,
  // modification start - 12.16.2022 - loki-ribeiro-sc-277
  ...accountingNavTree,
  // modification end - 12.16.2022 - loki-ribeiro-sc-277
  // Modification start - 03.07.2023 - olain-tarcina-sc-451
  ...salesNavTree,
  // Modification end - 12.07.2023 - olain-tarcina-sc-451
  ...superadminNavTree,
  ...helpNavTree,
  // modification start - 08.29.2022 - ympshipyard
  ...shipyardNavTree,
  // modification end - 08.29.2022 - ympshipyard
  // Modification start - 08.22.2024 - nmaco-magsael-sc-677
  ...liteYmpNavTree,
  // Modification end - 08.22.2024 - nmaco-magsael-sc-677
  // Modification start - 09.22.2024 - maco-magsael-sc-689
  ...charterNavTree,
  // Modification end - 09.22.2024 - maco-magsael-sc-689
  // Modification start - 09.26.2024 - maco-magsael-sc-691
  ...tyssalesNavTree,
  // Modification end - 09.26.2024 - maco-magsael-sc-691
];

export default navigationConfig;
