import {
  GET_LITEYMPEQUIPMENT,
  LITEYMPEQUIPMENT_ERROR,
  SHOW_LITEYMPEQUIPMENT_LOADING,
  GET_LITEYMPEQUIPMENT_DETAILS,
  CREATE_LITEYMPEQUIPMENT_SUCCESS,
  SHOW_EDIT_LITEYMPEQUIPMENT_LOADING,
  EDIT_LITEYMPEQUIPMENT_SUCCESS,
  DELETE_LITEYMPEQUIPMENT,
  MIGRATE_LITEYMPEQUIPMENT_SUCCESS,
} from '../actions/types';

const initialState = {
  liteympequipmentlist: [],
  liteympequipmentID: null,
  liteympequipmentDetails: null,
  liteympequipmentLoading: false,
  editLiteympequipmentLoading: false,
  error: {},
};

/**
 * maco-magsael-sc-677: Created for YMP Lite - Interface for lite equipment and jobs
 */

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEYMPEQUIPMENT_LOADING:
      return {
        ...state,
        liteympequipmentLoading: true,
      };
    case MIGRATE_LITEYMPEQUIPMENT_SUCCESS:
      return {
        ...state,
        liteympequipmentLoading: false,
      };
    case SHOW_EDIT_LITEYMPEQUIPMENT_LOADING:
      return {
        ...state,
        editLiteympequipmentLoading: true,
      };
    case GET_LITEYMPEQUIPMENT:
      return {
        ...state,
        liteympequipmentlist: payload,
        liteympequipmentLoading: false,
      };
    case EDIT_LITEYMPEQUIPMENT_SUCCESS:
      return {
        ...state,
        liteympequipmentlist: state.liteympequipmentlist.map((equipment) =>
          equipment._id === payload._id
            ? {
                ...equipment,
                name: payload.name,
                location: payload.location,
                modelNumber: payload.modelNumber,
                serialNumber: payload.serialNumber,
                manufacturer: payload.manufacturer,
                remarks: payload.remarks,
                critical: payload.critical,
              }
            : equipment
        ),
        editLiteympequipmentLoading: false,
        liteympequipmentLoading: false,
      };
    case GET_LITEYMPEQUIPMENT_DETAILS:
      return {
        ...state,
        liteympequipmentDetails: payload,
        liteympequipmentLoading: false,
      };
    case CREATE_LITEYMPEQUIPMENT_SUCCESS:
      return {
        ...state,
        liteympequipmentlist: [payload, ...state.liteympequipmentlist],
        liteympequipmentLoading: false,
      };
    case LITEYMPEQUIPMENT_ERROR:
      return {
        ...state,
        error: payload,
        liteympequipmentLoading: false,
      };
    case DELETE_LITEYMPEQUIPMENT:
      return {
        ...state,
        liteympequipmentlist: state.liteympequipmentlist.filter(
          (group) => group._id !== payload
        ),
        liteympequipmentLoading: false,
      };
    default:
      return state;
  }
}
