import {
  GET_LITEYMPJOB,
  LITEYMPJOB_ERROR,
  SHOW_LITEYMPJOB_LOADING,
  GET_LITEYMPJOB_DETAILS,
  CREATE_LITEYMPJOB_SUCCESS,
  SHOW_EDIT_LITEYMPJOB_LOADING,
  EDIT_LITEYMPJOB_SUCCESS,
  DELETE_LITEYMPJOB,
  MIGRATE_LITEYMPJOB_SUCCESS,
} from '../actions/types';

const initialState = {
  liteympjoblist: [],
  liteympjobID: null,
  liteympjobDetails: null,
  liteympjobLoading: false,
  editLiteympjobLoading: false,
  error: {},
};

/**
 * maco-magsael-sc-677: Created for YMP Lite - Interface for lite equipment and jobs
 */

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEYMPJOB_LOADING:
      return {
        ...state,
        liteympjobLoading: true,
      };
    case MIGRATE_LITEYMPJOB_SUCCESS:
      return {
        ...state,
        liteympjobLoading: false,
      };
    case SHOW_EDIT_LITEYMPJOB_LOADING:
      return {
        ...state,
        editLiteympjobLoading: true,
      };
    case GET_LITEYMPJOB:
      return {
        ...state,
        liteympjoblist: payload,
        liteympjobLoading: false,
      };
    case EDIT_LITEYMPJOB_SUCCESS:
      return {
        ...state,
        liteympjoblist: state.liteympjoblist.map((job) =>
          job._id === payload._id
            ? {
                ...job,
                title: payload.title,
                equipment: payload.equipment,
                equipmentID: payload.equipmentID,
                description: payload.description,
                repeating: payload.repeating,
                unplanned: payload.unplanned,
                hours: payload.hours,
                days: payload.days,
                department: payload.department,
                date: payload.date,
              }
            : job
        ),
        liteympjobDetails: {
          ...payload,
          title: payload.title,
          equipment: payload.equipment,
          equipmentID: payload.equipmentID,
          description: payload.description,
          repeating: payload.repeating,
          unplanned: payload.unplanned,
          hours: payload.hours,
          days: payload.days,
          department: payload.department,
          date: payload.date,
        },
        editLiteympjobLoading: false,
        liteympjobLoading: false,
      };
    case GET_LITEYMPJOB_DETAILS:
      return {
        ...state,
        liteympjobDetails: payload,
        liteympjobLoading: false,
      };
    case CREATE_LITEYMPJOB_SUCCESS:
      return {
        ...state,
        liteympjoblist: [payload, ...state.liteympjoblist],
        liteympjobLoading: false,
      };
    case LITEYMPJOB_ERROR:
      return {
        ...state,
        error: payload,
        liteympjobLoading: false,
      };
    case DELETE_LITEYMPJOB:
      return {
        ...state,
        liteympjoblist: state.liteympjoblist.filter(
          (group) => group._id !== payload
        ),
        liteympjobLoading: false,
      };
    default:
      return state;
  }
}
